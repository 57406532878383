import { Collection, Proposal } from '@/models';
import ProposalStatusEnum from '@/enums/proposal/status';
import ProposalFundingStatusEnum from '@/enums/proposal/fundingStatus';
import ProposalApplicationStatusEnum from '@/enums/proposal/applicationStatus';

import i18n from '@/lib/i18n';

export class ProposalCollection extends Collection {
    static type = 'ProposalCollection';
    static endpoint = Proposal.api.index;
    static model = Proposal;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable()
        };
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        return filters;
    }

    get headers() {
        return [
            this.createHeader('client_name', i18n.t('client'), {
                filterable: true,
                path: 'client.name',
                width: '10rem'
            }),
            this.createHeader('offer_id', i18n.t('offer'), {
                width: '18rem',
                classList: ['no-wrap'],
                sortable: 'offer.name',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Offer'),
                    filters: {
                        off_panel: true,
                        closed: true
                    }
                },
                transform: data => data.item?.offer?.name
            }),
            this.createHeader('proposed_at', i18n.t('proposed_at'), {
                filterable: true,
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: ProposalStatusEnum,
                enumA11yKey: 'enums.proposal_status'
            }),
            this.createHeader('amount', i18n.t('amount'), {
                filterable: true,
                format: 'money',
                width: '12rem',
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('funding_status', i18n.t('funding_status'), {
                filterable: true,
                width: '10rem',
                align: 'right',
                format: 'enum',
                enum: ProposalFundingStatusEnum,
                enumA11yKey: 'enums.proposal_funding_status',
                enumTypes: {
                    [ProposalFundingStatusEnum.AWAITING_FUNDS]: 'grey',
                    [ProposalFundingStatusEnum.FUNDS_AVAILABLE]: 'warning',
                    [ProposalFundingStatusEnum.FUNDS_TRANSFERRED]: 'success'
                }
            }),
            this.createHeader('application_status', i18n.t('application_status'), {
                filterable: true,
                width: '10rem',
                align: 'right',
                format: 'enum',
                enum: ProposalApplicationStatusEnum,
                enumA11yKey: 'enums.proposal_application_status',
                enumTypes: {
                    [ProposalApplicationStatusEnum.PENDING]: 'grey',
                    [ProposalApplicationStatusEnum.SENT]: 'info',
                    [ProposalApplicationStatusEnum.SIGNED]: 'success',
                    [ProposalApplicationStatusEnum.EXPIRED]: 'error',
                    [ProposalApplicationStatusEnum.ABORTED]: 'error'
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '11rem',
                persistent: true
            })
        ];
    }

    get default_headers() {
        return ['client_name', 'offer_id', 'proposed_at', 'status', 'amount', 'funding_status', 'application_status'];
    }
}

export default ProposalCollection;
