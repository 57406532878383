import { Organisation, VisibilityGroup } from '@/models';
import AdviserApi from '@/api/AdviserApi';

export class Adviser extends Organisation {
    static entity = 'advisers';
    static Api = AdviserApi;

    static fields() {
        return {
            ...super.fields(),
            parent_id: this.string(null).nullable(),
            custom_app_url: this.string(null).nullable(),
            auth_client_id: this.string(null).nullable(),
            auth_client_secret: this.string(null).nullable(),
            default_panel_id: this.string(null).nullable(),
            default_panel: this.attr(null).nullable(),
            primary_contact: this.attr(null).nullable(),
            public_adviser_id: this.string(null).nullable(),
            visibility_groups: this.modelList(VisibilityGroup, []).nullable(),
            new_registrations_advised: this.boolean(false).nullable(),
            new_registrations_contact_id: this.string(null).nullable()
        };
    }

    get primary_contact_name() {
        return this.primary_contact ? this.primary_contact.name : null;
    }
}

export default Adviser;
