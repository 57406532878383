import BaseApi from '@/api/BaseApi';

export class ReportApi extends BaseApi {
    static resource = 'reports';

    get endpoints() {
        return {
            getTransactionReconciliation: this.getTransactionReconciliation.bind(this),
            getPositionReconciliation: this.getPositionReconciliation.bind(this),
            getClientsCustodialAccounts: this.getClientsCustodialAccounts.bind(this),
            getClientSummary: this.getClientSummary.bind(this),
            getDividendTransactions: this.getDividendTransactions.bind(this),
            getPendingApplications: this.getPendingApplications.bind(this),
            getRegistrations: this.getRegistrations.bind(this),
            getProposals: this.getProposals.bind(this),
            getAuthActivity: this.getAuthActivity.bind(this),
            getInvestorDisposals: this.getInvestorDisposals.bind(this),
            getInvestorNegligibleValue: this.getInvestorNegligibleValue.bind(this),
            getInvestorTaxYear: this.getInvestorTaxYear.bind(this),
            getVctMaturity: this.getVctMaturity.bind(this),
            getHoldings: this.getHoldings.bind(this),
            getTranches: this.getTranches.bind(this),
            getPmiTranches: this.getPmiTranches.bind(this),
            portfolioSummary: this.portfolioSummary.bind(this)
        };
    }

    async getTransactionReconciliation(params) {
        return await BaseApi.api.get(`${this.base_url}/transaction-reconciliation`, params);
    }

    async getPositionReconciliation() {
        return await BaseApi.api.get(`${this.base_url}/position-reconciliation`);
    }

    async getClientsCustodialAccounts() {
        return await BaseApi.api.get(`${this.base_url}/ops/clients-report`);
    }

    async getClientSummary() {
        return await BaseApi.api.get(`${this.base_url}/ops/client-summary`);
    }

    async getDividendTransactions(investor_id, params) {
        return await BaseApi.api.get(`${this.base_url}/ops/dividend-transactions/${investor_id}`, params);
    }

    async getPendingApplications() {
        return await BaseApi.api.get(`${this.base_url}/ops/pending-applications`);
    }

    async getRegistrations() {
        return await BaseApi.api.get(`${this.base_url}/ops/registrations-report`);
    }

    async getProposals() {
        return await BaseApi.api.get(`${this.base_url}/ops/proposals-report`);
    }

    async getAuthActivity() {
        return await BaseApi.api.get(`${this.base_url}/security/auth-activity`);
    }

    async getInvestorDisposals(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return await this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/investor-disposals`
        });
    }

    async getInvestorNegligibleValue(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return await this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/investor-negligible-value`
        });
    }

    async getVctMaturity(offset = null, limit = null, sort_by = null, filters = {}, config = {}) {
        return await this.index(offset, limit, sort_by, filters, {
            ...config,
            base_url: `${this.base_url}/vct-maturity`
        });
    }

    async getInvestorTaxYear(investorId, taxYear, filters = {}) {
        return await BaseApi.api.get(`${this.base_url}/investor-tax-year`, {
            investor_id: investorId,
            tax_year: taxYear,
            ...filters
        });
    }

    async getHoldings() {
        return await BaseApi.api.get(`${this.base_url}/billing/holdings`);
    }

    async getTranches() {
        return await BaseApi.api.get(`${this.base_url}/billing/tranches`);
    }

    async getPmiTranches() {
        return await BaseApi.api.get(`${this.base_url}/billing/pmi-tranches`);
    }

    async portfolioSummary(filters = {}, config = {}) {
        let investorExtract = BaseApi.extractFilter(filters, 'investor_id');
        let taxStatusExtract = BaseApi.extractFilter(investorExtract.filters, 'tax_status');

        return await BaseApi.api.get(
            `${this.base_url}/portfolio/summaries/${taxStatusExtract.value}`,
            BaseApi.removeNull({
                ...taxStatusExtract.filters,
                investor_id: investorExtract.value
            }),
            config
        );
    }
}

export default ReportApi;
