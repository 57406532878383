import { BaseModel, User } from '@/models';

export class ProposalSignatory extends BaseModel {
    static entity = 'proposal-signatories';

    static fields() {
        return {
            ...super.fields(),
            cc: this.boolean(true).nullable(),
            role: this.string(null).nullable(),
            user_id: this.string(null).nullable(),
            user: this.belongsTo(User, 'user_id')
        };
    }
}

export default ProposalSignatory;
