import clock from '@/lib/clock';
import dayjs from 'dayjs';

/**
 * Datetime Formatting to ISO 8601
 * @param {String} value
 * @returns {String}
 */
const toIso8601 = (value, local = false) => {
    if (!value) {
        return null;
    }

    let date = value;

    if (date instanceof Date || date instanceof dayjs) {
        date = date.toISOString();
    } else {
        const parsedAsUnix = clock.unix(Number(date));
        if (parsedAsUnix.isValid() && parsedAsUnix.unix().toString() === String(date)) {
            date = parsedAsUnix.toISOString();
        }
    }

    // Convert 'DD/MM/YYYY' to 'YYYY-MM-DD'
    if (/\d{2}\/\d{2}\/\d{4}/.test(date)) {
        date = date.split('/').reverse().join('-');
    }

    // Append time as 00:00:00 if not set
    if (!date.includes(' ') && !date.includes('T')) {
        date += 'T00:00:00';
    }

    // Append 'Z' if date doesn't specify a timezone and it's not local time
    if (!local && !date.endsWith('Z') && !date.includes('+')) {
        date += 'Z';
    }

    try {
        date = local ? clock(date) : clock.utc(date);

        // Convert to user's local time in ISO 8601 format
        return date.tz(clock.tz.guess()).toISOString();
    } catch (error) {
        return null;
    }
};

export default toIso8601;
