import Vue from 'vue';
import isLive from '@/lib/helpers/isLive';

const mockApi = isLive() ? false : localStorage.getItem('mock_api') === 'true';

export default {
    app: {
        namespaced: true,
        state: {
            mock_api: mockApi,
            query_selector: {},
            //
            modal: {
                active: false,
                key: null,
                props: {},
                callback: () => {}
            },
            //
            show_media_library: false,
            media_library_heading: null,
            media_library_collection: null,
            media_library_model: null,
            media_library_selected_callback: () => {},
            media_library_multiple: false,
            media_library_preselected: null,
            //
            table_display_format: null,
            //
            request_user_feedback_modal: false,
            request_user_feedback_modal_options: {},
            //
            loading: {
                active: false,
                title: null,
                text: null,
                progress: null
            },
            progress_queue: {
                active: false,
                title: null,
                items: []
            },
            breadcrumbs: [],
            aborted: []
        },
        getters: {
            mock_api: state => state.mock_api,
            query_selector: state => key => state.query_selector?.[`${key}`],
            //
            modal: state => state.modal,
            //
            show_media_library: state => state.show_media_library,
            media_library_heading: state => state.media_library_heading,
            media_library_collection: state => state.media_library_collection,
            media_library_model: state => state.media_library_model,
            media_library_selected_callback: state => state.media_library_selected_callback,
            media_library_multiple: state => state.media_library_multiple,
            media_library_preselected: state => state.media_library_preselected,
            //
            table_display_format: state => state.table_display_format,
            //
            request_user_feedback_modal: state => state.request_user_feedback_modal,
            request_user_feedback_modal_options: state => state.request_user_feedback_modal_options,
            //
            loading: state => state.loading,
            progress_queue: state => state.progress_queue,
            has_progress_queue: state => state.progress_queue.active,
            breadcrumbs: state => state.breadcrumbs,
            aborted: state => state.aborted
        },
        actions: {
            toggleMockApi({ commit }) {
                commit('TOGGLE_MOCK_API');
            },
            clearMockData({ commit }) {
                commit('CLEAR_MOCK_DATA');
            },
            //
            setModal({ commit }, { key, props = {}, callback = () => {} }) {
                commit('SET_MODAL', { key, props, callback });
            },
            setModalVisibility({ commit }, active) {
                commit('SET_MODAL_VISIBILITY', active);
            },
            resetModal({ commit }) {
                commit('RESET_MODAL');
            },
            //
            setMediaLibraryVisibility({ commit }, active) {
                commit('SET_MEDIA_LIBRARY_VISIBILITY', active);
            },
            setMediaLibrary(
                { commit },
                { collection, onSelected, multiple = false, preselected = null, heading = null }
            ) {
                commit('SET_MEDIA_LIBRARY_VISIBILITY', false);
                commit('SET_MEDIA_LIBRARY_HEADING', heading);
                commit('SET_MEDIA_LIBRARY_COLLECTION', collection);
                commit('SET_MEDIA_LIBRARY_SELECTED_CALLBACK', onSelected);
                commit('SET_MEDIA_LIBRARY_MULTIPLE', multiple);
                commit('SET_MEDIA_LIBRARY_PRESELECTED', preselected);
            },
            resetMediaLibrary({ commit }) {
                commit('SET_MEDIA_LIBRARY_VISIBILITY', false);
                commit('SET_MEDIA_LIBRARY_HEADING', null);
                commit('SET_MEDIA_LIBRARY_COLLECTION', null);
                commit('SET_MEDIA_LIBRARY_SELECTED_CALLBACK', () => {});
                commit('SET_MEDIA_LIBRARY_MULTIPLE', false);
                commit('SET_MEDIA_LIBRARY_PRESELECTED', null);
            },
            showUserFeedbackModal({ commit }, options = {}) {
                commit('SHOW_USER_FEEDBACK_MODAL', options);
            },
            hideUserFeedbackModal({ commit }) {
                commit('HIDE_USER_FEEDBACK_MODAL');
            },
            addQuerySelector({ commit }, querySelector = { key: null, value: null }) {
                if (querySelector.key) {
                    commit('SET_QUERY_SELECTOR', querySelector);
                }
            },
            removeQuerySelector({ commit }, key) {
                commit('SET_QUERY_SELECTOR', { key, value: null });
            },
            setLoading({ commit }, loading = {}) {
                loading = {
                    active: false,
                    title: null,
                    text: null,
                    progress: null,
                    buttons: [],
                    ...loading
                };

                if (loading === false) {
                    loading = { active: false, title: null, text: null, progress: null, buttons: [] };
                }

                if (loading === true) {
                    loading = { active: true, title: null, text: null, progress: null, buttons: [] };
                }

                commit('SET_LOADING', loading);
            },
            setLoadingTitle({ commit }, title = null) {
                commit('SET_LOADING_TITLE', title);
            },
            setLoadingText({ commit }, text = null) {
                commit('SET_LOADING_TEXT', text);
            },
            setLoadingProgress({ commit }, progress = null) {
                commit('SET_LOADING_PROGRESS', progress);
            },
            setLoadingButtons({ commit }, buttons = []) {
                commit('SET_LOADING_BUTTONS', buttons);
            },
            //
            setProgressQueue({ commit }, progressQueue = {}) {
                progressQueue = {
                    active: false,
                    title: null,
                    items: [],
                    ...progressQueue
                };

                if (progressQueue === false) {
                    progressQueue = { active: false, title: null, items: [] };
                }

                if (progressQueue === true) {
                    progressQueue = { active: true, title: null, items: [] };
                }

                commit('SET_PROGRESS_QUEUE', progressQueue);
            },
            setProgressQueueTitle({ commit }, title = null) {
                commit('SET_PROGRESS_QUEUE_TITLE', title);
            },
            setProgressQueueItems({ commit }, items = []) {
                const ids = items.map(item => item.id);
                items = items.filter((item, index) => ids.indexOf(item.id) === index);
                commit('SET_PROGRESS_QUEUE_ITEMS', items);
            },
            prependProgressQueueItems({ commit, state }, items = []) {
                const oldItems = state.progress_queue.items.filter(item => !items.map(i => i.id).includes(item.id));
                items = items.concat(oldItems);

                const ids = items.map(item => item.id);
                items = items.filter((item, index) => ids.indexOf(item.id) === index);
                commit('SET_PROGRESS_QUEUE_ITEMS', items);
            },
            updateProgressQueueItem({ commit }, { id, data }) {
                commit('UPDATE_PROGRESS_QUEUE_ITEM', { id, data });
            },
            //
            setBreadcrumbs({ commit }, breadcrumbs = []) {
                commit('SET_BREADCRUMBS', breadcrumbs);
            },
            abort({ commit }, id) {
                commit('ABORT', id);
            },
            abortClean({ commit }, id) {
                commit('ABORT_CLEAN', id);
            }
        },
        mutations: {
            TOGGLE_MOCK_API(state) {
                if (isLive()) {
                    state.mock_api = false;
                    return;
                }

                state.mock_api = !state.mock_api;
                localStorage.setItem('mock_api', state.mock_api);
                window.location.reload();
            },
            CLEAR_MOCK_DATA() {
                Object.keys(localStorage).forEach(key => {
                    if (key.startsWith('mock_') && key !== 'mock_api') {
                        localStorage.removeItem(key);
                    }
                });

                window.location.reload();
            },
            //
            SET_MODAL_VISIBILITY(state, active) {
                state.modal.active = active;
            },
            SET_MODAL(state, { key, props = {}, callback = () => {} }) {
                state.modal = { active: true, key, props, callback };
            },
            RESET_MODAL(state) {
                state.modal = { active: false, key: null, props: {}, callback: () => {} };
            },
            //
            SET_MEDIA_LIBRARY_VISIBILITY(state, active) {
                state.show_media_library = active;
            },
            SET_MEDIA_LIBRARY_HEADING(state, heading) {
                state.media_library_heading = heading;
            },
            SET_MEDIA_LIBRARY_COLLECTION(state, collection) {
                state.media_library_collection = collection;
                state.media_library_model = collection ? collection.constructor.model : null;
            },
            SET_MEDIA_LIBRARY_MODEL(state, model) {
                state.media_library_model = model;
            },
            SET_MEDIA_LIBRARY_SELECTED_CALLBACK(state, callback) {
                state.media_library_selected_callback = callback;
            },
            SET_MEDIA_LIBRARY_MULTIPLE(state, multiple) {
                state.media_library_multiple = multiple;
            },
            SET_MEDIA_LIBRARY_PRESELECTED(state, preselected) {
                state.media_library_preselected = preselected;
            },
            //
            SHOW_USER_FEEDBACK_MODAL(state, options = {}) {
                state.request_user_feedback_modal = true;
                state.request_user_feedback_modal_options = options;
            },
            HIDE_USER_FEEDBACK_MODAL(state) {
                state.request_user_feedback_modal = false;
                state.request_user_feedback_modal_options = {};
            },
            //
            SET_LOADING(state, { active, title, text, progress, buttons }) {
                state.loading = { active, title, text, progress, buttons };
            },
            SET_LOADING_TITLE(state, title) {
                state.loading.title = title;
            },
            SET_LOADING_TEXT(state, text) {
                state.loading.text = text;
            },
            SET_LOADING_PROGRESS(state, progress) {
                state.loading.progress = progress;
            },
            SET_LOADING_BUTTONS(state, buttons) {
                state.loading.buttons = buttons;
            },
            //
            SET_PROGRESS_QUEUE(state, { active, title, items }) {
                state.progress_queue = { active, title, items };
            },
            SET_PROGRESS_QUEUE_TITLE(state, title) {
                state.progress_queue.title = title;
            },
            SET_PROGRESS_QUEUE_ITEMS(state, items) {
                state.progress_queue.items = items;
            },
            UPDATE_PROGRESS_QUEUE_ITEM(state, { id, data }) {
                const index = state.progress_queue.items.findIndex(item => item.id === id);
                if (index !== -1) {
                    const item = state.progress_queue.items[`${index}`];

                    for (const key in data) {
                        item[`${key}`] = data[`${key}`];
                    }

                    Vue.set(state.progress_queue.items, index, item);
                }
            },
            //
            SET_QUERY_SELECTOR(state, querySelector = {}) {
                state.query_selector[querySelector.key] = querySelector.value;
            },
            SET_BREADCRUMBS(state, breadcrumbs = []) {
                state.breadcrumbs = breadcrumbs;
            },
            //
            ABORT(state, id) {
                if (!state.aborted.includes(id)) {
                    state.aborted.push(id);
                }
            },
            ABORT_CLEAN(state, id) {
                state.aborted = state.aborted.filter(item => item !== id);
            }
        }
    }
};
