import { Collection, Offer } from '@/models';
import i18n from '@/lib/i18n';
import taxStatusEnum from '@/enums/taxStatus';
import OfferStatusEnum from '@/enums/offer/status';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import SDRLabelEnum from '@/enums/SDRLabel';

export class SystemOffersCollection extends Collection {
    static type = 'SystemOffersCollection';
    static endpoint = Offer.api.index;
    static model = Offer;

    static fields() {
        return {
            ...super.fields(),
            show_approved_panel: this.boolean(false),
            closed: this.boolean(true)
        };
    }

    get default_headers() {
        return ['name', 'gi_ref', 'tax_status', 'status', 'close_at', 'workflow_status'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '12rem',
                minWidth: '12rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '10rem'
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: taxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '8rem'
            }),
            this.createHeader('close_at', i18n.t('close_at'), {
                format: 'datetime',
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('workflow_status', i18n.t('workflow_status'), {
                format: 'enum',
                width: '9rem',
                filterable: {
                    operators: this.getOperators(['eq', 'neq', 'in'])
                },
                enum: OfferWorkflowStatusEnum,
                enumA11yKey: 'enums.offer_workflow_status',
                enumTypes: {
                    [OfferWorkflowStatusEnum.DRAFT]: 'grey',
                    [OfferWorkflowStatusEnum.LIVE]: 'success'
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                format: 'enum',
                filterable: true,
                width: '7rem',
                enum: OfferStatusEnum,
                enumA11yKey: 'enums.offer_status',
                enumTypes: {
                    [OfferStatusEnum.OPEN]: 'info',
                    [OfferStatusEnum.CLOSED]: 'error',
                    [OfferStatusEnum.EVERGREEN]: 'success',
                    [OfferStatusEnum.COMING_SOON]: 'warning'
                }
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            }),

            this.createHeader('sdr_label', i18n.t('sdr_label'), {
                format: 'enum',
                filterable: true,
                enum: SDRLabelEnum,
                enumA11yKey: 'enums.sdr_label',
                width: '8rem',
                active: false
            })
        ];
    }

    get preset_filters() {
        let filters = {
            workflow_status: `${getFilterOperator('in')}:[${OfferWorkflowStatusEnum.DRAFT},${
                OfferWorkflowStatusEnum.LIVE
            }]`
        };

        if (!this.show_approved_panel) {
            filters.off_panel = 'true';
        }

        if (this.closed) {
            filters.closed = 'true';
        }

        return filters;
    }
}

export default SystemOffersCollection;
