import { BaseModel, AppStepperItem } from '@/models';

export class AppStepper extends BaseModel {
    static entity = 'appSteppers';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            items: this.hasMany(AppStepperItem, 'stepper_id'),
            data: this.attr(null).nullable(),
            active_step_id: this.string(null).nullable(),
            //
            transition_forward_name: this.string('scroll-x-reverse-transition'),
            transition_backward_name: this.string('scroll-x-transition'),
            transition: this.string(null).nullable(),
            //
            ready: this.boolean(false)
        };
    }

    get steps() {
        return this.items.filter(item => !item.disabled);
    }

    get step() {
        return this.steps.find(step => step.id === this.active_step_id);
    }

    get step_index() {
        return this.steps.indexOf(this.step);
    }

    get first_step() {
        return this.steps[0];
    }

    get is_first_step() {
        return this.step_index === 0;
    }

    get final_step() {
        return this.steps[this.steps.length - 1];
    }

    get is_final_step() {
        return this.step.id === this.final_step.id;
    }

    get next_step() {
        return this.steps[this.step_index + 1];
    }

    get previous_step() {
        return this.steps[this.step_index - 1];
    }

    setup() {
        this.transition = this.transition_forward_name;
        this.active_step_id = this.first_step.id;
        this.ready = true;
    }

    go_forwards() {
        this.transition = this.transition_forward_name;
        this.active_step_id = this.next_step.id;
        return this.next_step;
    }

    go_backwards() {
        if (this.step.id === this.first_step.id) {
            return null;
        }

        this.transition = this.transition_backward_name;
        this.active_step_id = this.previous_step.id;
        return this.previous_step;
    }
}

export default AppStepper;
