import { Editor } from '@tiptap/vue-2';
import extensions from '@/lib/tiptap/extensions';

const createEditor = options => {
    options = {
        extensions,
        content: null,
        autofocus: false,
        editable: true,
        injectCSS: true,
        onBeforeCreate: () => {
            // Before the view is created.
        },
        onCreate: () => {
            // The editor is ready.
        },
        onUpdate: () => {
            // The editor has been updated.
        },
        onSelectionUpdate: () => {
            // Selection has changed
        },
        onTransaction: () => {
            // The editor state has changed.
        },
        onFocus: () => {
            // The editor has been focused.
        },
        onBlur: () => {
            // The editor has been blurred.
        },
        onDestroy: () => {
            // The editor is being destroyed.
        },
        editorProps: {
            transformPastedText(text) {
                return text;
            },
            transformPastedHTML(html) {
                return html;
            }
        },
        ...options
    };

    if (options.editable) {
        // Disable open on click when editing.
        options.extensions = options.extensions.map(extension => {
            if (extension.name === 'link') {
                extension.options.openOnClick = false;
            }
            return extension;
        });
    }

    return new Editor(options);
};

export default createEditor;
