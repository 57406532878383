import Vue from 'vue';
import axios from 'axios';
import config from '@/config';
import { stringify, parse } from 'qs';
import { redirectToLogin } from '@/lib/auth';
import get from 'lodash/isNull';

export const axiosConfig = {
    baseURL: config.API_BASE_URL,
    timeout: config.API_TIMEOUT,
    withCredentials: true,
    maxRedirects: 0,
    paramsSerializer: {
        encode: parse,
        serialize: params => stringify(params, { arrayFormat: 'repeat' })
    }
};

const http = axios.create(axiosConfig);

http.interceptors.response.use(null, error => {
    if (get(error, 'response.status') === 401 || get(error, 'request.status') === 401) {
        redirectToLogin({
            rd: window.location.href,
            app_uri: window.location.host
        });
    }
    return Promise.reject(error);
});

Vue.prototype.$http = http;

export default http;
