import { BaseModel, Organisation, UserRole, PhoneNumber } from '@/models';
import UserStatusEnum from '@/enums/user/status';
import MarketingConsentEnum from '@/enums/marketingConsent';
import OrganisationTypeEnum from '@/enums/organisation/type';
import snack from '@/lib/helpers/snack';
import i18n from '@/lib/i18n';
import UserApi from '@/api/UserApi';

export class User extends BaseModel {
    static entity = 'users';
    static Api = UserApi;

    static fields() {
        return {
            ...super.fields(),
            //
            $verifyingEmail: this.boolean(false).nullable(),
            $verificationEmailSent: this.boolean(false).nullable(),
            $verificationEmailError: this.string(null).nullable(),
            //
            id: this.string(null).nullable(),
            status: this.enum(UserStatusEnum).nullable(),
            gi_ref: this.string(null).nullable(),
            title: this.string(null).nullable(),
            name: this.string(null).nullable(),
            email: this.string(null).nullable(),
            email_verified: this.boolean(null).nullable(),
            dob: this.string(null).nullable(),
            job_title: this.string(null).nullable(),
            national_insurance: this.string(null).nullable(),
            bio: this.string(null).nullable(),
            fca_number: this.string(null).nullable(),
            nationality: this.attr(null).nullable(),
            domiciled: this.attr(null).nullable(),
            tax_residency: this.attr(null).nullable(),
            tax_cert_destination: this.string(null).nullable(),
            us_person: this.boolean(null).nullable(),
            politically_exposed: this.boolean(null).nullable(),
            can_login: this.boolean(null).nullable(),
            phone_numbers: this.modelList(PhoneNumber).nullable(),
            consent_marketing: this.enum(MarketingConsentEnum).nullable(),
            consent_marketing_date: this.string(null).nullable(),
            organisation_id: this.string(null).nullable(),
            roles: this.modelList(UserRole).nullable(),
            add_to_creators_visibility_group: this.boolean(null).nullable(),
            //
            organisation_type: this.enum(OrganisationTypeEnum).nullable(),
            organisation: this.belongsTo(Organisation, 'organisation_id')
        };
    }

    async $verifyEmail(email) {
        if (!this.id) {
            throw new ReferenceError('Missing ID required while attempting to send verification request');
        }

        this.$verificationEmailSent = false;
        this.$verificationEmailError = null;
        this.$verifyingEmail = true;

        // Wait 2 seconds to prevent spamming the API
        await new Promise(resolve => setTimeout(resolve, 2000));

        try {
            await User.api.verifyEmail(this.id, email);

            snack({
                timeout: 20000,
                type: 'success',
                text: i18n.t('check_your_email_to_verify_your_email_address')
            });

            this.$verificationEmailSent = true;
        } catch (error) {
            const message = error.response?.data?.message || error.message;

            snack({
                timeout: 20000,
                type: 'error',
                text: message
            });

            this.$verificationEmailError = message;
        }

        await User.api.get(this.id);

        this.$verifyingEmail = false;
        return User.find(this.id);
    }

    async $setupMfa() {
        if (!this.id) {
            throw new ReferenceError('Missing ID required while attempting to setup MFA');
        }

        this.$saving = ['mfa'];

        await User.api.setupMfa(this.id);

        this.$saving = [];

        snack({
            type: 'success',
            text: `Check the inbox of ${this.email} to set up Multi-Factor Authentication`
        });

        await User.api.get(this.id);
        return User.find(this.id);
    }
}

export default User;
