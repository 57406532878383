<template>
    <v-dialog
        v-model="active"
        :max-width="width"
        :app="app"
        :persistent="persistent"
        :fullscreen="fullscreen"
        :hide-overlay="hideOverlay"
        overlay-opacity="0.8"
        scrollable
    >
        <v-card :class="classList">
            <template v-if="findSlot('header')">
                <slot name="header" />
            </template>
            <template v-else>
                <v-card-title v-if="findSlot('overline')" class="text-overline pb-0">
                    <slot name="overline" />
                </v-card-title>
                <v-card-title v-else-if="overline" class="text-overline pb-0">
                    {{ overline }}
                </v-card-title>
                <v-spacer v-else class="pt-5"></v-spacer>
                <v-card-title
                    v-if="findSlot('title') || title"
                    class="text-h5 pt-0 pb-0 flex-nowrap word-wrap-break-word"
                    :class="cardTitleClassList"
                >
                    <slot v-if="findSlot('title')" name="title" />
                    <template v-else>{{ title }}</template>
                    <v-spacer v-if="findSlot('header-actions')" />
                    <slot v-if="findSlot('header-actions')" name="header-actions" />
                </v-card-title>
            </template>

            <slot v-if="findSlot('before')" name="before" class="app-dialog__before" />

            <v-card-text v-if="text" class="no-overflow flex-grow-1 flex-shrink-0">
                <template v-if="Array.isArray(text)">
                    <div v-for="(textLine, index) in text" :key="`text-${index}`">
                        {{ textLine }}
                    </div>
                </template>
                <div v-else>{{ text }}</div>
            </v-card-text>

            <v-card-text v-if="findSlot('default')" class="px-0 pb-0">
                <slot name="default" class="app-dialog__content" />
            </v-card-text>

            <slot v-if="findSlot('after')" name="after" class="app-dialog__after" />

            <v-card-actions v-if="!hideActions">
                <slot v-if="findSlot('actions')" name="actions" v-bind="args" />
                <template v-else>
                    <v-spacer v-if="args.button.no.hide"></v-spacer>
                    <app-button
                        v-if="!args.button.cancel.hide"
                        class="mr-2"
                        secondary
                        :color="args.button.cancel.color"
                        :text="args.button.cancel.text"
                        :disabled="retrying"
                        :icon="args.button.cancel.icon"
                        :test-id="args.button.cancel.testId"
                        @click="args.button.cancel.action"
                    />
                    <v-spacer v-if="!args.button.no.hide"></v-spacer>
                    <app-button
                        v-if="!args.button.no.hide"
                        class="mr-4"
                        secondary
                        :color="args.button.no.color"
                        :text="args.button.no.text"
                        :disabled="retrying"
                        :icon="args.button.no.icon"
                        :test-id="args.button.no.testId"
                        @click="args.button.no.action"
                    />
                    <app-button
                        v-if="!args.button.continue.hide"
                        :text="args.button.continue.text"
                        :color="args.button.continue.color"
                        :loading="args.button.continue.loading || loading || retrying"
                        :disabled="args.button.continue.disabled || disabled || retrying"
                        :icon="args.button.continue.icon"
                        :test-id="args.button.continue.testId"
                        @click="args.button.continue.action"
                    />
                </template>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import i18n from '@/lib/i18n';
export default {
    name: 'AppDialog',
    props: {
        value: {
            type: Boolean,
            default: true
        },
        app: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'medium' // x-small, small, medium, large, x-large, xx-large, fullscreen
        },
        color: {
            type: String,
            default: 'primaryText'
        },
        overline: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        text: {
            type: [String, Array],
            default: null
        },
        cancelButtonText: {
            type: String,
            default: i18n.t('cancel')
        },
        noButtonText: {
            type: String,
            default: i18n.t('no')
        },
        continueButtonText: {
            type: String,
            default: i18n.t('proceed')
        },
        cancelButtonColor: {
            type: String,
            default: null
        },
        noButtonColor: {
            type: String,
            default: null
        },
        continueButtonColor: {
            type: String,
            default: null
        },
        cancelButtonIcon: {
            type: String,
            default: null
        },
        cancelButtonTestId: {
            type: String,
            default: null
        },
        noButtonIcon: {
            type: String,
            default: null
        },
        noButtonTestId: {
            type: String,
            default: null
        },
        continueButtonIcon: {
            type: String,
            default: null
        },
        continueButtonDisabled: {
            type: Boolean,
            default: false
        },
        continueButtonTestId: {
            type: String,
            default: null
        },
        continueButtonLoading: {
            type: Boolean,
            default: false
        },
        onCancelFn: {
            type: Function,
            default: () => {}
        },
        onNoFn: {
            type: Function,
            default: () => {}
        },
        onContinueFn: {
            type: Function,
            default: () => {}
        },
        afterContinueFn: {
            type: Function,
            default: () => {}
        },
        onErrorFn: {
            type: Function,
            default: () => {}
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        hideCancelButton: {
            type: Boolean,
            default: false
        },
        hideNoButton: {
            type: Boolean,
            default: true
        },
        hideContinueButton: {
            type: Boolean,
            default: false
        },
        hideOnContinue: {
            type: Boolean,
            default: true
        },
        hideBeforeContinue: {
            type: Boolean,
            default: false
        },
        hideOverlay: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        retrying: false
    }),
    computed: {
        args() {
            return {
                color: this.color,
                retrying: this.retrying,
                disabled: this.disabled,
                button: {
                    cancel: {
                        hide: this.hideCancelButton,
                        text: this.cancelButtonText,
                        action: this.onCancel,
                        color: this.cancelButtonColor || 'primaryText',
                        icon: this.cancelButtonIcon,
                        testId: this.cancelButtonTestId
                    },
                    no: {
                        hide: this.hideNoButton,
                        text: this.noButtonText,
                        action: this.onNo,
                        color: this.noButtonColor || 'error',
                        icon: this.noButtonIcon,
                        testId: this.noButtonTestId
                    },
                    continue: {
                        hide: this.hideContinueButton,
                        text: this.continueButtonText,
                        action: this.onContinue,
                        color: this.continueButtonColor || this.color,
                        icon: this.continueButtonIcon,
                        disabled: this.continueButtonDisabled,
                        loading: this.continueButtonLoading,
                        testId: this.continueButtonTestId
                    }
                }
            };
        },
        fullscreen() {
            return this.size === 'fullscreen';
        },
        width() {
            let width = '';
            let sizeValue = this.size || 'medium';

            switch (sizeValue) {
                case 'x-small':
                    width = '200';
                    break;
                case 'small':
                    width = '310';
                    break;
                case 'medium':
                    width = '410';
                    break;
                case 'large':
                    width = '570';
                    break;
                case 'x-large':
                    width = '1000';
                    break;
                case 'xx-large':
                    width = '80%';
                    break;
                case 'fullscreen':
                    width = '100%';
                    break;
            }

            return width;
        },
        active: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        colorValue() {
            if (!this.color) {
                return '';
            }

            return this._.head(this.color.split(' '));
        },
        shadeValue() {
            if (!this.color) {
                return '';
            }

            const split = this.color.split(' ');

            if (split.length === 2) {
                return this._.last(split);
            }

            return '';
        },
        cardTitleClassList() {
            let classList = [];

            if (this.colorValue) {
                classList.push(this.colorValue + '--text');
            }

            if (this.shadeValue) {
                classList.push('text--' + this.shadeValue);
            }

            return classList.join(' ');
        },
        classList() {
            let classList = ['app-dialog'];

            if (this.disabled) {
                classList.push('app-dialog--disabled');
            }

            return classList.join(' ');
        }
    },
    methods: {
        async onCancel() {
            this.active = false;
            this.$emit('cancel');
            await this.onCancelFn();
        },
        async onNo() {
            this.active = false;
            this.$emit('no');
            await this.onNoFn();
        },
        async onContinue() {
            if (this.hideBeforeContinue) {
                this.active = false;
            }

            try {
                this.retrying = true;
                await this.onContinueFn();
                await this.afterContinueFn();
                this.retrying = false;
            } catch (error) {
                console.error(error);
                await this.onErrorFn(error);
            }

            if (this.hideOnContinue) {
                this.active = false;
            }

            this.$emit('continue');
        }
    }
};
</script>

<style lang="scss">
.app-dialog {
    .app-form__content {
        font-size: 0.875rem;
        padding: 0 1.8rem !important;
    }
    .v-card__actions {
        padding: 1rem;
        background: var(--v-primaryLight-base);
        border-top: 1px solid rgba(0, 0, 0, 0.05);
    }
}
</style>
