import BaseApi from '@/api/BaseApi';

export class UserApi extends BaseApi {
    static resource = 'users';

    get endpoints() {
        return {
            ...super.endpoints,
            verifyEmail: this.verifyEmail.bind(this),
            setupMfa: this.setupMfa.bind(this),
            logoutAllDevices: this.logoutAllDevices.bind(this)
        };
    }

    async verifyEmail(id, email) {
        const response = await BaseApi.api.post(`${this.base_url}/${id}/verify-email`, { email });

        return this._respond(response, () => {
            this._updateItemInCollections(id, response.data);
        });
    }

    async setupMfa(id) {
        const response = await BaseApi.api.post(`${this.base_url}/${id}/setup-mfa`);

        return this._respond(response, () => {
            this._updateItemInCollections(id, response.data);
        });
    }

    async logoutAllDevices(id) {
        return await BaseApi.api.get(`${this.base_url}/${id}/forcelogout`);
    }
}

export default UserApi;
