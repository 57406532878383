import icons from '@/lib/vuetify/icons';
import has from 'lodash/has';
import startsWith from 'lodash/startsWith';
import camelCase from 'lodash/camelCase';

/**
 * Return the icon for the given string
 * @param {String} key
 * @returns {String}
 */
const getIcon = (key = 'default', prefix = null) => {
    if (
        startsWith(key, 'fas ') ||
        startsWith(key, 'far ') ||
        startsWith(key, 'fal ') ||
        startsWith(key, 'fad ') ||
        startsWith(key, 'fab ')
    ) {
        return key;
    }

    key = camelCase(key);

    if (!has(icons, key)) {
        return 'fal fa-question-circle';
    }

    let icon = icons[`${key}`];

    if (prefix) {
        icon = icon.replace('fas ', '');
        icon = icon.replace('far ', '');
        icon = icon.replace('fal ', '');
        icon = icon.replace('fad ', '');
        icon = icon.replace('fab ', '');

        return `${prefix} ${icon}`;
    }

    return icon;
};

export default getIcon;
