import i18n from '@/lib/i18n';

export default [
    {
        path: '/hello',
        name: 'InvestorOnboarding',
        component: () =>
            import(
                /* webpackChunkName: "investor-onboarding" */
                '@/views/InvestorOnboarding.vue'
            ),
        meta: {
            title: i18n.t('hello')
        }
    }
];
