const InvestorCategoryEnum = Object.freeze({
    HIGH_NET_WORTH: 'HIGH_NET_WORTH',
    ELECTIVE_PROFESSIONAL: 'ELECTIVE_PROFESSIONAL',
    PROFESSIONAL: 'PROFESSIONAL', // GI Only
    RETAIL_ADVISED: 'RETAIL_ADVISED',
    RETAIL_RESTRICTED: 'RETAIL_RESTRICTED',
    CERTIFIED_SOPHISTICATED: 'CERTIFIED_SOPHISTICATED', // GI Only
    SELF_CERT_SOPHISTICATED: 'SELF_CERT_SOPHISTICATED'
});

export default InvestorCategoryEnum;
