import { BaseModel, AppStepper } from '@/models';
import uuid from '@/lib/helpers/uuid';

export class AppStepperItem extends BaseModel {
    static entity = 'appStepperItems';

    static fields() {
        return {
            id: this.uid(() => uuid()),
            name: this.string(''),
            overline: this.string(null).nullable(),
            subtitle: this.string(null).nullable(),
            icon: this.string(null).nullable(),
            component: this.attr(null).nullable(),
            card_size: this.string(null).nullable(),
            stepper_id: this.string(null).nullable(),
            stepper: this.belongsTo(AppStepper, 'stepper_id'),
            disabled: this.boolean(false),
            data: this.attr(null).nullable()
        };
    }

    get key() {
        return this.name.toLowerCase().replace(/\s/g, '_');
    }
}

export default AppStepperItem;
