import { Media, Offer, Product } from '@/models';
import MediaTypeEnum from '@/enums/media/type';
import MediaMarketingTypeEnum from '@/enums/media/marketing/type';
import snakeCase from 'lodash/snakeCase';
import MediaMarketingApi from '@/api/MediaMarketingApi';

export class MediaMarketing extends Media {
    static entity = 'mediaMarketing';
    static Api = MediaMarketingApi;

    static fields() {
        return {
            ...super.fields(),
            library_type: this.string(MediaTypeEnum.MARKETING),
            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id'),
            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),
            type: this.enum(MediaMarketingTypeEnum)
        };
    }

    static getMediaTypeKey(mediaType, append = '') {
        let key = 'key_media';
        let type = snakeCase(mediaType);

        if (type === 'im') {
            type = 'prospectus';
        } else if (type === 'dd') {
            type = 'due_diligence';
        } else if (type === 'kid') {
            type = 'key_information_document';
        }

        return `${key}_${type}${append}`;
    }
}

export default MediaMarketing;
