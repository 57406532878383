import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import getFilterOperator from '@/lib/helpers/getFilterOperator';

export function toSearchFilters(criteria, filters = {}, filterKey = 'name', filterOperator = 'CONTAINS') {
    if (isObject(criteria)) {
        filters = {
            ...filters,
            ...mapValues(criteria, value => (value.includes(':') ? value : `${getFilterOperator('CONTAINS')}:${value}`))
        };
    } else {
        filters = {
            ...filters,
            [filterKey]: `${getFilterOperator(filterOperator)}:${criteria}`
        };
    }

    return filters;
}

export default toSearchFilters;
