import config from '@/config';
import has from 'lodash/has';

export function isInvestor(organisation) {
    return organisation && has(organisation, 'type') && organisation.type === 'INVESTOR';
}

export function isAdviser(organisation) {
    return organisation && has(organisation, 'type') && organisation.type === 'ADVISER';
}

export function isProvider(organisation) {
    return organisation && has(organisation, 'type') && organisation.type === 'PRODUCT_PROVIDER';
}

export function isCompany(organisation) {
    return organisation && has(organisation, 'type') && organisation.type === 'COMPANY';
}

export function getHomeRoute(organisation) {
    if (isProvider(organisation)) {
        return { name: 'OffersAll' };
    } else if (isCompany(organisation)) {
        return { name: 'OffersAll' };
    }

    return { name: 'Dashboard' };
}

export function redirectToLogin(params = {}, newTab = false) {
    const loginUrl = new URL('/oauth2/start', config.API_BASE_URL);

    if (newTab) {
        if ('rd' in params) {
            const rdUrl = new URL(params.rd);
            rdUrl.searchParams.append('closeAfterSuccessfulLogin', true);
            params.rd = rdUrl.href;
        }

        for (const key in params) {
            loginUrl.searchParams.append(key, params[`${key}`]);
        }

        window.open(loginUrl.href);
    } else {
        for (const key in params) {
            loginUrl.searchParams.append(key, params[`${key}`]);
        }

        window.location.href = loginUrl.href;
    }
}

export function redirectToLogout(returnUrl, signoutUrl = null) {
    const logoutUrl = new URL('/oauth2/sign_out', config.API_BASE_URL);

    if (signoutUrl) {
        signoutUrl = new URL(signoutUrl);
        signoutUrl.searchParams.append('redirect_uri', returnUrl);
        logoutUrl.searchParams.append('rd', signoutUrl.href);
    }

    localStorage.removeItem('currentOrganisation');
    localStorage.removeItem('currentAccount');
    window.location.href = logoutUrl.href;
}

export function changeContext(organisation, account) {
    const rd = new URL(window.location.href);

    if (has(account, 'base_url') && account.base_url) {
        rd.host = account.base_url;
    } else if (has(organisation, 'base_url') && organisation.base_url) {
        rd.host = organisation.base_url;
    }

    rd.searchParams.append('currentOrganisation', organisation.id);

    if (account) {
        rd.searchParams.append('currentAccount', account.adviser_id);
    }

    window.location.href = rd.href;
}

export function changeLogin(user, organisation, account) {
    const rd = new URL(window.location.href);

    const params = {
        login_hint: user.email,
        prompt: 'login',
        app_uri: organisation.base_url
    };

    rd.searchParams.append('currentOrganisation', organisation.id);

    if (account) {
        params.app_uri = account.base_url;
        rd.searchParams.append('currentAccount', account.adviser_id);
    }

    rd.host = params.app_uri;
    params.rd = rd.toString();

    redirectToLogin(params);
}
