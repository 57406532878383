// import { isValidColour, adjustHue, toHex } from '@/lib/colour';
import createColoursFromHex from '@/lib/helpers/createColoursFromHex';

/**
 * Creates Vuetify themes from a hex
 * @param {Object} colours
 * @returns Object
 */
const createThemeFromBranding = colours => {
    return {
        light: {
            primaryBackground: {
                base: colours.primaryBackground
            },
            primaryText: {
                base: colours.primaryText
            },
            primaryLight: {
                base: colours.primaryLight
            },
            //
            anchor: '#006fa5',
            background: {
                base: '#ffffff',
                lighten5: '#ffffff',
                lighten4: '#ffffff',
                lighten3: '#ffffff',
                lighten2: '#ffffff',
                lighten1: '#ffffff',
                darken: '#f9f9f9',
                darken1: '#f9f9f9',
                darken2: '#f5f5f5',
                darken3: '#eeeeee',
                darken4: '#dddddd'
            },
            error: {
                base: '#ff5252',
                lighten5: '#ffe4d5',
                lighten4: '#ffc6b9',
                lighten3: '#ffa99e',
                lighten2: '#ff8c84',
                lighten1: '#ff6f6a',
                darken1: '#df323b',
                darken2: '#bf0025',
                darken3: '#9f0010',
                darken4: '#800000'
            },
            info: {
                base: '#2196f3',
                lighten5: '#d9edfd',
                lighten4: '#b5ffff',
                lighten3: '#95e8ff',
                lighten2: '#75ccff',
                lighten1: '#51b0ff',
                darken1: '#007cd6',
                darken2: '#0064ba',
                darken3: '#004d9f',
                darken4: '#003784'
            },
            success: createColoursFromHex('#39b985', {
                lighten5: '#e6f6ef'
            }),
            warning: {
                base: '#fb8c00',
                lighten5: '#ffe9e9',
                lighten4: '#fffb82',
                lighten3: '#ffdf67',
                lighten2: '#ffc24b',
                lighten1: '#ffa72d',
                darken1: '#db7200',
                darken2: '#bb5900',
                darken3: '#9d4000',
                darken4: '#802700'
            }
        }
    };
};

export default createThemeFromBranding;
