import { HoldingCollection, Holding } from '@/models';
import i18n from '@/lib/i18n';
import PmiStructureEnum from '@/enums/pmi/structure';

export class HoldingPmiCollection extends HoldingCollection {
    static type = 'HoldingPmiCollection';
    static endpoint = Holding.api.byTranche;

    get headers() {
        let headers = [
            // https://growthinvest.atlassian.net/browse/CORE-878
            // this.createHeader('investor_name', i18n.t('investor'), {
            //     path: 'investor.name',
            //     width: '15rem',
            //     minWidth: '15rem'
            // }),
            this.createHeader('id', i18n.t('id'), {
                width: '20rem',
                classList: ['no-wrap'],
                sortable: false
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '14rem',
                minWidth: '14rem',
                transform: data => {
                    if (data.item?.multi_investment === true) {
                        return i18n.t('co_invest');
                    }
                    return data.item.name;
                },
                sortable: false
            }),
            this.createHeader('structure', i18n.t('structure'), {
                width: '8rem',
                format: 'enum',
                enum: PmiStructureEnum,
                enumA11yKey: 'enums.pmi_structure',
                sortable: false
            }),
            this.createHeader('agreement_on', i18n.t('lpa_agreement_date'), {
                format: 'date',
                width: '9rem',
                sortable: false
            }),
            this.createHeader('denomination', i18n.t('denomination'), {
                width: '9rem',
                sortable: false
            }),
            this.createHeader('total_commitment_gbp', `${i18n.t('total_commitment')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9.6rem',
                sortable: false
            }),
            this.createHeader('investment_amount_gbp', `${i18n.t('investment_amount')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('cumulative_loan_contribution_gbp', `${i18n.t('cumulative_loan_contribution')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem',
                sortable: false
            }),
            this.createHeader('cumulative_net_contributions_gbp', `${i18n.t('cumulative_net_contributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11.4rem',
                sortable: false
            }),
            this.createHeader('undrawn_capital_gbp', `${i18n.t('undrawn_capital')} / ${i18n.t('commitment')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '11rem',
                sortable: false
            }),
            this.createHeader('distributions_gbp', `${i18n.t('distributions')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('nav_gbp', `${i18n.t('nav')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('current_value_gbp', `${i18n.t('current_value')} (gbp)`, {
                column: 'current_value',
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                active: !this.show_nav_totals,
                sortable: false
            }),
            this.createHeader('total_return_gbp', `${i18n.t('total_return')} (gbp)`, {
                format: 'money',
                formatOptions: super.money_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                format: 'percentage',
                formatOptions: super.percentage_format_options,
                width: '9rem',
                sortable: false
            }),
            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '9.2rem',
                sortable: false
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                filterable: true,
                format: 'boolean',
                width: '9rem',
                sortable: false
            })
        ];

        return headers;
    }

    get default_headers() {
        return [
            'name',
            // 'structure',
            'agreement_on',
            'denomination',
            'total_commitment_gbp',
            'investment_amount_gbp',
            'cumulative_loan_contribution_gbp',
            'cumulative_net_contributions_gbp',
            'undrawn_capital_gbp',
            'distributions_gbp',
            'nav_gbp',
            'current_value',
            'total_return_gbp',
            'total_return_percentage',
            'total_return_moic'
        ];
    }

    get pdf_headers() {
        return [
            'name',
            // 'structure',
            'agreement_on',
            'denomination',
            'total_commitment_gbp',
            'investment_amount_gbp',
            'cumulative_loan_contribution_gbp',
            'cumulative_net_contributions_gbp',
            'undrawn_capital_gbp',
            'distributions_gbp',
            'nav_gbp',
            'current_value',
            'total_return_gbp',
            'total_return_percentage',
            'total_return_moic'
        ];
    }

    get preset_filters() {
        return {
            ...super.preset_filters,
            tax_status: this.filterOperator('is') + ':pmi'
        };
    }
}

export default HoldingPmiCollection;
