const ProposalStatusEnum = Object.freeze({
    SHORTLISTED: 'SHORTLISTED',
    ABORTED: 'ABORTED',
    REJECTED: 'REJECTED',
    PROPOSED: 'PROPOSED',
    IN_PROGRESS: 'IN_PROGRESS',
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    PENDING_ALLOTMENT: 'PENDING_ALLOTMENT',
    COMPLETED: 'COMPLETED'
});

export default ProposalStatusEnum;
