/* eslint-disable max-len */

export const external_urls = {
    companieshouse: 'https://find-and-update.company-information.service.gov.uk/company',
    facebook: 'https://facebook.com',
    growthinvest_investor_tcs: 'https://growthinvest.com/investor-terms-conditions/',
    growthinvest_privacy_agreement: 'https://growthinvest.com/privacy/',
    growthinvest_risk_summary: 'https://growthinvest.com/risk-summary/',
    james_brearley_oep:
        'https://www.jbrearley.co.uk/individual-investors/wp-content/uploads/2023/08/Order-Execution-Policy-Aug-23.pdf',
    james_brearley_privacy_policy: 'https://www.jbrearley.co.uk/privacy-policy/',
    james_brearley_tcs:
        'https://www.jbrearley.co.uk/individual-investors/wp-content/uploads/2023/08/JB-Service-Terms-and-Conditions-Aug-2023.pdf',
    linkedin: 'https://linkedin.com',
    lse: 'https://www.londonstockexchange.com',
    twitter: 'https://twitter.com',
    woodside_tcs: 'https://woodsidecorporateservices.co.uk/WCSL_Investor-Terms-and-Conditions.pdf'
};

export default external_urls;
