import Vue from 'vue';
import Vuex from 'vuex';

import VuexORM from '@vuex-orm/core';
// import VuexORMisDirtyPlugin from '@vuex-orm/plugin-change-flags';

// VuexORM.use(VuexORMisDirtyPlugin);

import { AuthModel } from '@/models/Auth';

import {
    Branding,
    Collection,
    Activity,
    Account,
    Organisation,
    Adviser,
    Broker,
    BusinessStage,
    Company,
    CompanyNews,
    Custodian,
    ExchangeListing,
    Fees,
    Fund,
    FundNews,
    FundManager,
    FundStructure,
    Holding,
    Integration,
    IhtSector,
    Introducer,
    InvestmentFocus,
    InvestmentStrategy,
    Investor,
    InvestorRegistration,
    InvestorAdviserRelationship,
    Nominee,
    Offer,
    Product,
    Proposal,
    Provider,
    Registrar,
    ReceivingAgent,
    Sector,
    Shortlist,
    Transaction,
    User,
    RemoteApplication,
    RestrictedPanel,
    RestrictedPanelEntry,
    Media,
    MediaFile,
    MediaAdviser,
    MediaMarketing,
    MediaInvestor,
    CorporateAction,
    Tranche,
    ProductOffer,
    VisibilityGroup,
    Registration,
    Agreement
} from '@/models';

const database = new VuexORM.Database();
database.register(AuthModel);
database.register(Branding);
database.register(Collection);
database.register(Activity);
database.register(Adviser);
database.register(Broker);
database.register(BusinessStage);
database.register(Company);
database.register(CompanyNews);
database.register(Custodian);
database.register(ExchangeListing);
database.register(Fees);
database.register(Fund);
database.register(FundNews);
database.register(FundManager);
database.register(FundStructure);
database.register(Holding);
database.register(Integration);
database.register(IhtSector);
database.register(Introducer);
database.register(InvestmentFocus);
database.register(InvestmentStrategy);
database.register(Investor);
database.register(InvestorRegistration);
database.register(InvestorAdviserRelationship);
database.register(Nominee);
database.register(Offer);
database.register(Organisation);
database.register(Product);
database.register(Proposal);
database.register(Provider);
database.register(Registrar);
database.register(ReceivingAgent);
database.register(Sector);
database.register(Shortlist);
database.register(User);
database.register(RemoteApplication);
database.register(RestrictedPanel);
database.register(RestrictedPanelEntry);
database.register(Media);
database.register(MediaFile);
database.register(MediaAdviser);
database.register(MediaMarketing);
database.register(MediaInvestor);
database.register(Account);
database.register(Transaction);
database.register(CorporateAction);
database.register(Tranche);
database.register(ProductOffer);
database.register(VisibilityGroup);
database.register(Registration);
database.register(Agreement);

// Vuex Modules
import app from '@/store/modules/app';
import preferences from '@/store/modules/preferences';
import countries from '@/store/modules/countries';
import dialogQueue from '@/store/modules/dialogQueue';
import filterPanels from '@/store/modules/filterPanels';
import i18n from '@/store/modules/i18n';
import requests from '@/store/modules/requests';
import routerHistory from '@/store/modules/routerHistory';
import snackQueue from '@/store/modules/snackQueue';
import system from '@/store/modules/system';
import keyboard from '@/store/modules/keyboard';

Vue.use(Vuex);

export const storeConfig = {
    plugins: [VuexORM.install(database)],
    modules: {
        ...app,
        ...preferences,
        ...countries,
        ...dialogQueue,
        ...filterPanels,
        ...i18n,
        ...requests,
        ...routerHistory,
        ...snackQueue,
        ...system,
        ...keyboard
    }
};

export default new Vuex.Store(storeConfig);
