import Vue from 'vue';
import draggable from 'vuedraggable';

const components = {
    draggable,
    AppWrapper: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppWrapper.vue'
        ),
    AppDivider: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppDivider.vue'
        ),
    AppCopyButton: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppCopyButton.vue'
        ),
    AppSection: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppSection.vue'
        ),
    AppRow: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppRow.vue'
        ),
    AppField: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppField.vue'
        ),
    AppText: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppText.vue'
        ),
    AppSwitch: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppSwitch.vue'
        ),
    AppChip: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppChip.vue'
        ),
    AppTooltip: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppTooltip.vue'
        ),
    AppList: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppList.vue'
        ),
    AppLacuna: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppLacuna.vue'
        ),
    AppAlert: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppAlert.vue'
        ),
    AppAlertButton: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppAlertButton.vue'
        ),
    AppButton: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppButton.vue'
        ),
    AppButtonCancel: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppButtonCancel.vue'
        ),
    AppButtonClose: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppButtonClose.vue'
        ),
    AppLogoutButton: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppLogoutButton'
        ),
    AppCard: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppCard.vue'
        ),
    AppScrollbar: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppScrollbar.vue'
        ),
    AppWysiwyg: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppWysiwyg.vue'
        ),
    AppVideo: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppVideo.vue'
        ),
    AppFitText: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppFitText.vue'
        ),
    AppLink: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppLink.vue'
        ),
    AppImage: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppImage.vue'
        ),
    AppMediaPreview: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppMediaPreview.vue'
        ),
    AppTable: () =>
        import(
            /* webpackChunkName: "core" */
            './components/table/AppTable.vue'
        ),
    AppTablePanel: () =>
        import(
            /* webpackChunkName: "core" */
            './components/table/AppTablePanel.vue'
        ),
    AppPageHeader: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppPageHeader.vue'
        ),
    AppParamSelector: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppParamSelector.vue'
        ),
    AppProgress: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppProgress.vue'
        ),
    AppQuerySelector: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppQuerySelector.vue'
        ),
    AppQuestionnaire: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppQuestionnaire'
        ),
    AppQuestion: () =>
        import(
            /* webpackChunkName: "core" */
            './components/AppQuestion'
        ),
    AppFileDrop: () =>
        import(
            /* webpackChunkName: "core" */
            '@/components/AppFileDrop'
        ),
    AppEditableRow: () =>
        import(
            /* webpackChunkName: "core" */
            '@/components/AppEditableRow'
        ),
    TextAnimationLoader: () =>
        import(
            /* webpackChunkName: "core" */
            './components/TextAnimationLoader.vue'
        ),
    //
    KanbanColumn: () =>
        import(
            /* webpackChunkName: "core" */
            './components/layouts/parts/KanbanColumn.vue'
        ),
    KanbanBoard: () =>
        import(
            /* webpackChunkName: "core" */
            './components/layouts/parts/KanbanBoard.vue'
        ),
    //
    DisplayString: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/String.vue'
        ),
    DisplayBoolean: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Boolean.vue'
        ),
    DisplayNumber: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Number.vue'
        ),
    DisplayMoney: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Money.vue'
        ),
    DisplayDate: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Date.vue'
        ),
    DisplayDatetime: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Datetime.vue'
        ),
    DisplayPercentage: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Percentage.vue'
        ),
    DisplayContent: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Content.vue'
        ),
    DisplayPhoneNumber: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/PhoneNumber.vue'
        ),
    DisplayEmailAddress: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/EmailAddress.vue'
        ),
    DisplayLink: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Link.vue'
        ),
    DisplayEnum: () =>
        import(
            /* webpackChunkName: "core" */
            './components/display/Enum.vue'
        ),
    //
    EditBoolean: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Boolean.vue'
        ),
    EditString: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/String.vue'
        ),
    EditMoney: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Money.vue'
        ),
    EditNumber: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Number.vue'
        ),
    EditDate: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Date.vue'
        ),
    EditDatetime: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Datetime.vue'
        ),
    EditParagraph: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Paragraph.vue'
        ),
    EditPercentage: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Percentage.vue'
        ),
    EditSelection: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Selection.vue'
        ),
    EditContent: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Content.vue'
        ),
    EditFile: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/File.vue'
        ),
    EditEnum: () =>
        import(
            /* webpackChunkName: "core" */
            './components/edit/Enum.vue'
        ),
    //
    FieldString: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/String.vue'
        ),
    FieldEnum: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Enum.vue'
        ),
    FieldNumber: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Number.vue'
        ),
    FieldBoolean: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Boolean.vue'
        ),
    FieldDescription: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Description.vue'
        ),
    FieldDate: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Date.vue'
        ),
    FieldDatetime: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Datetime.vue'
        ),
    FieldMoney: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Money.vue'
        ),
    FieldPercentage: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Percentage.vue'
        ),
    FieldToggle: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Toggle.vue'
        ),
    FieldYear: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Year.vue'
        ),
    FieldTaxYear: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/TaxYear.vue'
        ),
    FieldMonth: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Month.vue'
        ),
    FieldDay: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Day.vue'
        ),
    FieldLookup: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Lookup.vue'
        ),
    FieldUrl: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Url.vue'
        ),
    FieldEmail: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Email.vue'
        ),
    FieldPhoneNumber: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/PhoneNumber.vue'
        ),
    FieldMediaUpload: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/MediaUpload.vue'
        ),
    FieldCurrency: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Currency.vue'
        ),
    FieldOptions: () =>
        import(
            /* webpackChunkName: "core" */
            './components/fields/Options.vue'
        ),

    // Skeleton
    SkeletonTableSimple: () =>
        import(
            /* webpackChunkName: "core" */
            './components/skeleton/TableSimple.vue'
        )
};

Object.entries(components).forEach(([name, component]) => Vue.component(name, component));
