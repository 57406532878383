import BaseApi from '@/api/BaseApi';
import { Investor, VisibilityGroup, InvestorAdviserRelationship } from '@/models';
import Collection from '@/models/Collection';

export class InvestorApi extends BaseApi {
    static resource = 'investors';

    get endpoints() {
        return {
            ...super.endpoints,
            postClassification: this.postClassification.bind(this),
            applyForInvestmentAccount: this.applyForInvestmentAccount.bind(this),
            getAppropriateness: this.getAppropriateness.bind(this),
            postAppropriateness: this.postAppropriateness.bind(this),
            getVisibilityGroups: this.getVisibilityGroups.bind(this),
            getApplicationForm: this.getApplicationForm.bind(this),
            getAdviserRelationship: this.getAdviserRelationship.bind(this),
            postAdviserRelationship: this.postAdviserRelationship.bind(this),
            putAdviserRelationship: this.putAdviserRelationship.bind(this),
            patchAdviserRelationship: this.patchAdviserRelationship.bind(this),
            removeAdviserRelationship: this.removeAdviserRelationship.bind(this),
            postDriEnrollment: this.postDriEnrollment.bind(this),
            providers: this.providers.bind(this),
            products: this.products.bind(this)
        };
    }

    _clearCollection(type) {
        type = type || this.model.name || null;
        if (!type) return;

        Collection.delete(collection => collection.type.includes(type));
        Collection.delete(collection => collection.type.includes('Client'));
    }

    _filterResponseData(data) {
        if (data && data.id && data.adviser_accounts) {
            data.adviser_accounts = data.adviser_accounts.map(aa => {
                aa.investor_id = data.id;
                aa.adviser_id = aa.adviser.id;
                return aa;
            });
        }

        return data;
    }

    async postClassification(investorId, payload = {}) {
        const response = await BaseApi.api.post(`${this.base_url}/${investorId}/classifications`, payload);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection();
            this._clearCollection('Client');
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async applyForInvestmentAccount(investorId, payload = {}) {
        const response = await BaseApi.api.post(`${this.base_url}/${investorId}/apply`, payload);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection();
            this._clearCollection('Client');
            Investor.delete(investorId);
            InvestorAdviserRelationship.delete(item => item.investor_id === investorId);
        });
    }

    async getAppropriateness(investorId) {
        const response = await BaseApi.api.get(`${this.base_url}/${investorId}/appropriateness`);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);

            if (Array.isArray(response.data.questions)) {
                response.data.questions = response.data.questions.map(question => {
                    if (Array.isArray(question.answers)) {
                        question.answers = question.answers.map(answer => {
                            if (
                                !answer.text ||
                                (typeof answer.text === 'object' && Object.keys(answer.text).length === 0)
                            ) {
                                answer.text = null;
                            }
                            return answer;
                        });
                    }
                    return question;
                });
            }
        });
    }

    async postAppropriateness(investorId, questions = []) {
        const response = await BaseApi.api.post(`${this.base_url}/${investorId}/appropriateness`, { questions });

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
        });
    }

    async getVisibilityGroups(investorId) {
        const response = await BaseApi.api.get(`${this.base_url}/${investorId}/visibility-groups`);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._insertModels(response.data, VisibilityGroup);
        });
    }

    async getApplicationForm(investorId, adviserId) {
        let url = `${this.base_url}`;

        if (investorId) {
            url += `/${investorId}`;
        }

        url += '/application-form';

        return await BaseApi.api.get(
            url,
            { adviser_id: adviserId },
            {
                headers: {
                    Accept: 'application/pdf'
                },
                responseType: 'blob',
                returnFullResponse: true
            }
        );
    }

    async providers(offset = null, limit = null, sort_by = null, filters = {}, config = { base_url: null }) {
        let extract = BaseApi.extractFilter(filters, 'investor_id');

        if (!extract.value) {
            throw new Error(`investor_id is required when requesting providers for an investor.`);
        }

        let url = `${config.base_url || this.base_url}`;

        if (extract.value) {
            url += `/${extract.value}`;
        }

        url += '/providers';

        return this.index(offset, limit, sort_by, extract.filters, {
            ...config,
            base_url: url
        });
    }

    async products(offset = null, limit = null, sort_by = null, filters = {}, config = { base_url: null }) {
        let extract = BaseApi.extractFilter(filters, 'investor_id');

        if (!extract.value) {
            throw new Error(`investor_id is required when requesting products for an investor.`);
        }

        let url = `${config.base_url || this.base_url}`;

        if (extract.value) {
            url += `/${extract.value}`;
        }

        url += '/products';

        return this.index(offset, limit, sort_by, extract.filters, {
            ...config,
            base_url: url
        });
    }

    async getAdviserRelationship(investor_id, adviser_id, params = {}, options = {}) {
        const response = await BaseApi.api.get(
            `${this.base_url}/${investor_id}/adviser-relationship/${adviser_id}`,
            params,
            options
        );

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            InvestorAdviserRelationship.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });
        });
    }

    async postAdviserRelationship(payload = {}) {
        const { investor_id, adviser_id } = payload;

        if (!investor_id) {
            throw new Error('Missing investor_id on POST investor/adviser relationship', payload);
        }
        if (!adviser_id) {
            throw new Error('Missing adviser_id on POST investor/adviser relationship', payload);
        }

        const response = await BaseApi.api.post(
            `${this.base_url}/${investor_id}/adviser-relationship/${adviser_id}`,
            payload
        );

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('InvestorAdviserRelationship');
            InvestorAdviserRelationship.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });

            const investor = Investor.find(investor_id);

            if (investor) {
                Investor.update({
                    where: investor_id,
                    data: {
                        adviser_accounts: [...investor.adviser_accounts, response.data]
                    }
                });
            }
        });
    }

    async putAdviserRelationship(investor_id, adviser_id, payload = {}) {
        if (!investor_id) {
            throw new Error('Missing investor_id on PUT investor/adviser relationship', payload);
        }
        if (!adviser_id) {
            throw new Error('Missing adviser_id on PUT investor/adviser relationship', payload);
        }

        const response = await BaseApi.api.put(
            `${this.base_url}/${investor_id}/adviser-relationship/${adviser_id}`,
            payload
        );

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('InvestorAdviserRelationship');
            InvestorAdviserRelationship.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });

            const investor = Investor.find(investor_id);

            if (investor) {
                Investor.update({
                    where: investor_id,
                    data: {
                        adviser_accounts: investor.adviser_accounts.map(aa => {
                            if (aa.adviser_id === adviser_id) {
                                return response.data;
                            }
                            return aa;
                        })
                    }
                });
            }
        });
    }

    async patchAdviserRelationship(investor_id, adviser_id, payload = {}) {
        if (!investor_id) {
            throw new Error('Missing investor_id on PATCH investor/adviser relationship', payload);
        }
        if (!adviser_id) {
            throw new Error('Missing adviser_id on PATCH investor/adviser relationship', payload);
        }

        const response = await BaseApi.api.patch(
            `${this.base_url}/${investor_id}/adviser-relationship/${adviser_id}`,
            payload
        );

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
            this._clearCollection('InvestorAdviserRelationship');
            InvestorAdviserRelationship.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });

            const investor = Investor.find(investor_id);

            if (investor) {
                Investor.update({
                    where: investor_id,
                    data: {
                        adviser_accounts: investor.adviser_accounts.map(aa => {
                            if (aa.adviser_id === adviser_id) {
                                return { ...aa, ...payload };
                            }
                            return aa;
                        })
                    }
                });
            }
        });
    }

    async removeAdviserRelationship(investor_id, adviser_id) {
        if (!investor_id) {
            throw new Error('Missing investor_id on DELETE investor/adviser relationship');
        }
        if (!adviser_id) {
            throw new Error('Missing adviser_id on DELETE investor/adviser relationship');
        }

        const response = await BaseApi.api.remove(`${this.base_url}/${investor_id}/adviser-relationship/${adviser_id}`);

        return this._respond(response, () => {
            this._clearCollection('InvestorAdviserRelationship');
            InvestorAdviserRelationship.delete(
                item => item.investor_id === investor_id && item.adviser_id === adviser_id
            );

            const investor = Investor.find(investor_id);

            if (investor) {
                Investor.update({
                    where: investor_id,
                    data: {
                        adviser_accounts: investor.adviser_accounts.filter(aa => aa.adviser_id !== adviser_id)
                    }
                });
            }
        });
    }

    async postDriEnrollment(payload = {}) {
        const { investor_id, product_id } = payload;

        if (!investor_id) {
            throw new Error('Missing investor_id on POST DRI enrollment', payload);
        }
        if (!product_id) {
            throw new Error('Missing product_id on POST DRI enrollment', payload);
        }

        const response = await BaseApi.api.post(`${this.base_url}/${investor_id}/products/${product_id}/dri`, payload);

        return this._respond(response, () => {
            response.data = this._filterResponseData(response.data);
        });
    }
}

export default InvestorApi;
