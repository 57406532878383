import BaseValidator from './BaseValidator';
import i18n from '@/lib/i18n';
import toNumber from '@/lib/helpers/toNumber';

export class MinValidator extends BaseValidator {
    static key = 'min';

    static validator(value, label, minimum = 0) {
        const isCharacterCount = typeof value === 'string';
        const isItemCount = Array.isArray(value);

        if (value && typeof value === 'object' && 'amount' in value) {
            value = parseInt(value.amount);
        } else if (value && (isCharacterCount || isItemCount)) {
            value = value.length;
        } else {
            value = parseInt(value);
        }

        minimum = parseInt(minimum);

        if (value < minimum) {
            if (isCharacterCount) {
                return i18n.t('validator.min_items', { field: label, minimum: toNumber(minimum), items: 'characters' });
            } else if (isItemCount) {
                return i18n.t('validator.min_items', { field: label, minimum: toNumber(minimum), items: 'items' });
            }
            return i18n.t('validator.min', { field: label, minimum: toNumber(minimum) });
        }

        return true;
    }

    static arguments() {
        return [];
    }
}

export default MinValidator;
