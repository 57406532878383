import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export default Extension.create({
    name: 'Readonly',

    addGlobalAttributes() {
        return [
            {
                types: ['tableHeader', 'tableCell'],
                attributes: {
                    readonly: {
                        default: false
                    }
                }
            }
        ];
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('eventHandler'),
                props: {
                    // @see https://prosemirror.net/docs/ref/#view.EditorProps
                    editable(state) {
                        const isReadonly = state.selection.$head.path.some(v => {
                            try {
                                return v.attrs.readonly === true;
                            } catch (error) {
                                return false;
                            }
                        });

                        return !isReadonly;
                    }
                }
            })
        ];
    }
});
