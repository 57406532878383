import { redirectToLogin } from '@/lib/auth';
import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';

const loadUser = async (to, from, next) => {
    if (isPublic(to) || Auth().onboarding) {
        next();
    } else {
        try {
            await Auth().setCurrentUser();
            next();
        } catch (error) {
            const httpStatus = error?.response?.status;

            Auth().user = null;
            Auth().account = null;
            Auth().organisation = null;
            Auth().investor = null;
            Auth().adviser = null;

            if (httpStatus === 404) {
                Auth().onboarding = true;
                next({ name: 'InvestorOnboarding' });
            } else if (httpStatus < 500) {
                return redirectToLogin({
                    rd: window.location.href,
                    app_uri: window.location.host
                });
            }
        }
    }
};

export default loadUser;
